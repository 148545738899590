import Cookies from 'js-cookie';
import 'whatwg-fetch';

// Load app config, then load user info and set it to window
const initializePhoneCom = async config => {

    let APP_CONFIG = config || await getAppConfig()

    let url = APP_CONFIG.get_user_info_url;
    console.log(`Attempting to load user credentials from ${url}`);

        let cookie;

        // Check if a CP Session ID is passed in the URL - check here first
        // before checking cookies to support multitab logins for CSRs
        if (window.location.href.indexOf('_sid') !== -1) {
            const queryString = window.location.search.substring(1);
            const parsedQueryString = parseQuery(queryString, ';');  // CP uses ';' to separate query string params
            let cpSessionFromUrl = parsedQueryString._sid;
            if (cpSessionFromUrl){
                cpSessionFromUrl = cpSessionFromUrl.replace('#', '');  // CP sometimes appends # to URL
                cookie = cpSessionFromUrl;
                console.log(`Got session ID from URL, not checking cookies`);
            }
        }
        else {
            cookie = Cookies.get(APP_CONFIG.cp_session_id_cookie_name);

            if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
                cookie = APP_CONFIG.cp_session_id; // this is to allow local testing never put a session_id in the app.config for a deployment
            }
        }

    if (!cookie) {
        console.log('No cookie, redirect to login');
        // todo SignInRedirect();
    }

    console.log(`Using auth cookie ${cookie}`);

    let fetchCredentials = 'include';
    let headers = {
        'Content-Type': 'application/json'
    };

    if (cookie) {
        headers['Authorization'] = `CP ${cookie}`;
        fetchCredentials = 'omit';
    }

    return fetch(url, {
        method: 'POST',
        body: JSON.stringify({}),
        headers: headers,
        credentials: fetchCredentials
    })
    .then((response) => {
        return response.json().then(
            res => {
                window.V5PHONECOM = {
                    stage: APP_CONFIG.stage,
                    v4ApiRoot: APP_CONFIG.v4ApiRoot,
                    v5ApiRoot: APP_CONFIG.v5ApiRoot,
                    v5ToolsRoot: APP_CONFIG.v5ToolsRoot,
                    redirect_url: APP_CONFIG.redirect_url,
                    cp_token: res.cp_session_token,
                    features: new Set(res.features),
                    phone_number: res.phone_numbers,
                    voip_id: res.account_id,
                    voip_phone_id: res.extension_id,
                }
                return res;
            }
        );
    }).catch((err) => {
        console.log(err);
        window.location.href = `${APP_CONFIG.redirect_url}${window.location.href}`;
    });
};

const getPhoneCom = () => {
    if (window.window.V5PHONECOM) {
        return new Promise(function(resolve, reject) {
            resolve(window.window.V5PHONECOM);
        });
    }
    return initializePhoneCom().then(phoneCom => {
        return new Promise(function(resolve, reject) {
            resolve(window.window.V5PHONECOM);
        });
    })
};

// Load app config and set it to the window
const getAppConfig = () => {
    console.log('loading appconfig');

     if( window.APP_CONFIG){
        return new Promise(function(resolve, reject) {
            resolve( window.APP_CONFIG);
        });
     }
     let configUrl = '/app.config'
     if(process.env.PUBLIC_URL) {
       configUrl =  `${process.env.PUBLIC_URL}/app.config`
     }

    return fetch(configUrl, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    })
    .then(response => {
        return response.json()
    })
    .then(res => {
        console.log('App Config:', res)
        return res
    })
    .catch((err) => {
        console.log(err);
    });
}

function parseQuery(queryString, delimiterChar) {
    var query = {};
    var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split(delimiterChar);
    for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
}

// Load app config and set it to the window
const logout = async () => {
    let app_config = await getAppConfig();
    let phoneCom = await getPhoneCom();
    let link = `${phoneCom.v5ApiRoot.replace('services', 'app')}/communicator/account/logout/?account_id=${phoneCom.voip_id}&extension_id=${phoneCom.voip_phone_id}`
    fetch(link, {method: 'GET', headers: {'Authorization': `CP ${phoneCom.cp_token}`}}).finally(() => {
        fetch(`${app_config.cpBase}/login?action=logout&_sid=${phoneCom.cp_token}`, {method: 'GET', mode: 'no-cors'}).finally(() => {
            window.location.href = `${app_config.redirect_url}${window.location.href}`
        })
    })

}

export {getAppConfig, initializePhoneCom, getPhoneCom, logout};