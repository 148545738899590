import React, { Component } from 'react'
import { connect } from 'react-redux'
import LoginScreen from './LoginScreen'
import ForgotPasswordScreen from './ForgotPasswordScreen'
import { switchView } from '../actions/view.js'
import { withStyles } from '@material-ui/core'
import './styles/main.css'

const mapStateToProps = state => ({
	currentView: state.currentView
})

const mapDispatchToProps = dispatch => ({
	switchView: view => dispatch(switchView(view))
})

const styles = theme => ({
	mainWrapper: {
		padding:	'30px 15px',
		maxWidth:	440,
		margin:		'auto',
		height:		'70%',
		display:	'flex',
		alignItems:	'center'
	},
	contentWrapper: {
		display:		'flex',
		flexDirection:	'column',
		justifyContent:	'center',
		width:			'100%',
		height:			'fit-content'
	},
	header: {
		fontSize:		36,
		fontWeight:		500,
		width:			'80%',
		margin:			'auto',
		marginBottom:	20,
		display:		'flex',
		justifyContent:	'center',
		color:			theme.palette.primary.main,
		content:		`url(${theme.icons.logo})`
	}
})

class App extends Component {

	render() {
		const { classes } = this.props
		return (
			<div className={classes.mainWrapper}>
				<div className={classes.contentWrapper}>
					<div className={classes.header}></div>
					{this.props.currentView === 'login' ? <LoginScreen onLoggedIn={this.props.onLoggedIn}/> : null}
					{this.props.currentView === 'forgot' ? <ForgotPasswordScreen/> : null}
				</div>
			</div>
		)
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(App))