import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
// import App from './App'
import * as serviceWorker from './serviceWorker'
import 'core-js/es'
import 'es6-promise/auto'

import Login from 'login'
import { theme } from 'get-theme'
import ErrorCatcher from 'error-catcher'

ReactDOM.render(
	<ErrorCatcher theme={theme}>
		<Login theme={theme} />
	</ErrorCatcher>,
	document.getElementById('root'))
window.document.title = `Login - ${(theme.appName || 'Login - My Phone.com')}`
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: registration => {
    const waitingServiceWorker = registration.waiting;
    if (waitingServiceWorker && waitingServiceWorker.state !== 'redundant') {
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
    }
  }
});
