import React, { Component } from 'react'
import { connect } from 'react-redux'
import PDCButton from 'pdc-button'
import { ErrorTooltip } from 'tooltips'
import api from '../util/api_v5'
import { switchView } from '../actions/view.js'
import { withStyles } from '@material-ui/core'
import { theme } from 'get-theme'

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
	switchView: view => dispatch(switchView(view))
})

const styles = theme => ({
	inputsWrapper:	theme.loginApp.inputsWrapper,
	inputWrapper:	theme.loginApp.inputWrapper,
	inputLabel:		theme.loginApp.inputLabel,
	buttonsWrapper:	theme.loginApp.buttonsWrapper,
	goBackToLogin:	theme.loginApp.switchViewLink,
	infoMessage: {
		textAlign:		'center',
		marginBottom:	15
	},
	emailSendInfo: {
		textAlign: 'center'
	}
})

class ForgotPasswordScreen extends Component {

	state = {email: '', emailSent: false, sendingRequest: false}

	onEmailChange = e => {
		let value = e.target.value
		this.setState({email: value})
		let isEmailValid = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(value)
		this.setState({isEmailValid})
	}

	onResetPasswordClick = async () => {
		this.setState({sendingRequest: true})
		let response = await api.forgotPassword(this.state.email)
		this.setState({email: '', isEmailValid: false, sendingRequest: false, emailSent: true})
	}

	onGoBackToLoginClick = () => this.props.switchView('login')

	render() {
		const { classes } = this.props
		return (
			<>
				{this.state.emailSent ?
					<div className={classes.emailSendInfo}>
						<span>If your email is associated with a {theme.loginAppName} account a password reset instructions will be sent to it.</span>
					</div>
				:
				<>
				<div className={classes.infoMessage}>Enter your email below and we'll send you a link to reset your password</div>
				<div className={classes.inputsWrapper}>
					<div className={classes.inputWrapper}>
						<input type='text' onChange={this.onEmailChange} value={this.state.email} autoFocus/>
						<div className={`${classes.inputLabel} ${this.state.email ? 'up' : ''} ${this.state.emailError ? 'error' : ''}`}>
							Email
						</div>
					</div>
				</div>
				<div className={classes.buttonsWrapper}>
					<ErrorTooltip
						title		= {this.state.email && !this.state.isEmailValid ? 'The email is invalid' : ''}
						placement	= 'top'
					>
						<div>
							<PDCButton
								onClick		= {this.onResetPasswordClick}
								disabled	= {!this.state.isEmailValid || this.state.sendingRequest}
							>
								{this.state.sendingRequest ? 'Sending ...' : 'Send reset link'}
							</PDCButton>
						</div>
					</ErrorTooltip>
				</div>
				</>}
				<div className={classes.goBackToLogin}><span onClick={this.onGoBackToLoginClick}>Go back to login</span></div>
			</>
		)
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordScreen))