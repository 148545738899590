import React from 'react'
import App from './src/components/App'
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import rootReducer from './src/reducers'
import { ThemeProvider } from '@material-ui/styles'
import withTheme from '@material-ui/core/styles/withTheme'
import { initializePhoneCom, getAppConfig } from 'phonecom'

const store = createStore(rootReducer)

class Login extends React.Component {

	componentDidMount = async () => {
		let config = await getAppConfig()
		window.V5PHONECOM = {v5ApiRoot: config.v5ApiRoot}
	}

	render() {
		return (
			<ThemeProvider theme={this.props.theme}>
				<Provider store={store}>
					<App/>
				</Provider>
			</ThemeProvider>
		)
	}
}

export default withTheme(Login)