import ajax from 'ajax'
import PhoneComUser from 'phone-com-user'
class API {
	static login = async (username, password, remember_me=false) => {
		let requestUrl	= `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/communicator/account/login`
		let response	= await ajax.post(requestUrl, {username, password}, 'CP', {withCredentials: true})
		return response.data || response
	}

	static forgotPassword = async email => {
		let requestUrl	= `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/communicator/account/forgot-password`
		let response	= await ajax.post(requestUrl, {email})
		return response.data
	}
}

export default API