import React, { Component } from 'react'
import { connect } from 'react-redux'
import PDCButton from 'pdc-button'
import PDCCheckbox from 'pdc-checkbox'
import { DefaultArrowTooltip, ErrorTooltip } from 'tooltips'
import api from '../util/api_v5'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import { switchView } from '../actions/view.js'
import { withStyles } from '@material-ui/core'
import {getAppConfig} from "phonecom";

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
	switchView: view => dispatch(switchView(view))
})

const styles = theme => ({
	inputsWrapper:	theme.loginApp.inputsWrapper,
	inputWrapper:	theme.loginApp.inputWrapper,
	inputLabel:		theme.loginApp.inputLabel,
	buttonsWrapper:	theme.loginApp.buttonsWrapper,
	forgotPassword:	theme.loginApp.switchViewLink,
	rememberDeviceWrapper: {
		display:		'flex',
		justifyContent:	'center',
		marginTop:		20,
		'& > div': {
			cursor:		'pointer',
			display:	'flex',
			alignItems:	'center',
			'& .remember-text': {
				lineHeight: 1
			}
		}
	},
	showHidePassword: {
		position:	'absolute',
		right:		0,
		top:		16,
		background:	'white',
		color:		'lightgray',
		cursor:		'pointer',
		zIndex:		1,
		'&:hover': {
			color: 'gray'
		}
	},
	credentialsSeparator: {
		border:		'none',
		borderTop:	'1px solid lightgray',
		margin:		0
	}
})

class LoginScreen extends Component {

	state = {
		email:			'',
		password:		'',
		hidePassword:	true,
		emailError:		false,
		passwordError:	false,
		loggingIn:		false,
		hasCredentials:	false,
		rememberDevice:	false
	}

	toggleShowHidePassword = () => this.setState({hidePassword: !this.state.hidePassword})

	onEmailChange = e => {
		let email = e.target.value
		this.setState({email})
		let password = this.state.password
		this.setState({hasCredentials: email && password})
		if (!email && !password) this.setState({emailError: false, passwordError: false})
	}

	onPasswordChange = e => {
		let password = e.target.value
		this.setState({password})
		let email = this.state.email
		this.setState({hasCredentials: password && email})
		if (!email && !password) this.setState({emailError: false, passwordError: false})
	}

	onLoginClick = () => this.login()

	onEnter = () => {
		if (this.state.loggingIn) return
		if (!this.state.email && !this.state.password) return
		this.login()
	}

	login = async () => {
		this.setState({emailError: false, passwordError: false})
		let email		= this.state.email
		let password	= this.state.password
		if (!email) this.setState({emailError: true})
		if (!password) this.setState({passwordError: true})
		if (!email || !password) return
		this.setState({loggingIn: true})
		let response = await api.login(email, password, this.state.rememberDevice)
		if (response === 'invalid' || !response.cp_token) {
			this.setState({loggingIn: false})
			return this.setState({emailError: true, passwordError: true})
		}else{
			let token = response.cp_token
			let config = await getAppConfig()
			let url_string = window.location.href;
			let url = new URL(url_string);
			let post_login_url = url.searchParams.get("post_login_url")
			window.location.replace(post_login_url || config.redirect_url);
		}
		// await this.props.onLoggedIn(token)
		this.setState({loggingIn: false})
	}

	onForgotPasswordClick = () => this.props.switchView('forgot')

	toggleRememberDevice = () => {
		this.setState({rememberDevice: !this.state.rememberDevice})
	}

	render() {
		const { classes } = this.props
		return (
			<>
				<div className={classes.inputsWrapper}>
					<div className={classes.inputWrapper}>
						<input
							className	= {this.state.emailError ? 'error' : ''}
							type		= 'text'
							value		= {this.state.email}
							onChange	= {this.onEmailChange}
							onKeyDown	= {e => e.key === 'Enter' ? this.onEnter() : null}
							autoFocus
						/>
						<div className={`${classes.inputLabel} ${this.state.email ? 'up' : ''} ${this.state.emailError ? 'error' : ''}`}>
							Email
						</div>
					</div>
					<hr className={classes.credentialsSeparator}/>
					<div className={classes.inputWrapper}>
						{!this.state.hidePassword ?
							<input
								className	= {this.state.passwordError ? 'error' : ''}
								type		= 'text'
								value		= {this.state.password}
								onChange	= {this.onPasswordChange}
								onKeyDown	= {e => e.key === 'Enter' ? this.onEnter() : null}
							/>
						: null}
						{this.state.hidePassword ?
							<input
								className	= {this.state.passwordError ? 'error' : ''}
								type		= 'password'
								value		= {this.state.password}
								onChange	= {this.onPasswordChange}
								onKeyDown	= {e => e.key === 'Enter' ? this.onEnter() : null}
							/>
						: null}
						<div className={classes.showHidePassword} onClick={this.toggleShowHidePassword}>
							<DefaultArrowTooltip
								title			= {this.state.hidePassword ? 'Show password' : 'Hide password'}
								placement		= 'right'
								interactive		= {true}
								leaveDelay		= {1}
								enterTouchDelay	= {0}
							>
								{this.state.hidePassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}
							</DefaultArrowTooltip>
						</div>
						<div className={`${classes.inputLabel} ${this.state.password ? 'up' : ''} ${this.state.passwordError ? 'error' : ''}`}>
							Password
						</div>
					</div>
				</div>
				<div className={classes.rememberDeviceWrapper}>
					<div onClick={this.toggleRememberDevice}>
						<PDCCheckbox checked={this.state.rememberDevice}/>
						<span className='remember-text'>Remember this device</span>
					</div>
				</div>
				<div className={classes.buttonsWrapper}>
					<ErrorTooltip
						title		= {this.state.loggingIn ? '' : !this.state.hasCredentials ? 'Insert email and password first' : ''}
						placement	= 'top'
					>
						<div>
							<PDCButton
								onClick		= {this.onLoginClick}
								disabled	= {this.state.loggingIn || !this.state.hasCredentials}
							>
								{this.state.loggingIn ? 'Logging in' : 'Login'}
							</PDCButton>
						</div>
					</ErrorTooltip>
				</div>
				<div className={classes.forgotPassword}><span onClick={this.onForgotPasswordClick}>Forgot password?</span></div>
			</>
		)
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(LoginScreen))