// This is a wrapper around axios for all our api calls
// Handles getting user token and catching 403 errors
// use this instead of axios in the same way
import axios from 'axios'
import PhoneComUser from 'phone-com-user'
import React from 'react'
import ReactDOM from 'react-dom'
import ErrorOverlay from 'error-overlay'

class Ajax {

	constructor() {
		let ajax = axios.create()
		ajax.interceptors.response.use(this.onSuccess, this.onError)
		this.ajax = ajax
	}

	getHeaders = scheme => {
		let pdc_token = PhoneComUser.getToken()
		return {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `${scheme} ${pdc_token}`
			}
		}
	}

	onError = error => {
		switch (error.code) {
		case 403:
			// Append an error modal prompting user to relogin
			// ReactDOM.render(<ErrorOverlay message={"Authentication Error"} redirect={PhoneComUser.getRedirectUrl()}/>, document.getElementById('error-root'))
			return error
		default:
			return error
		}
	}

	onSuccess = response => {
		if (typeof response.data === 'object' && response.data !== null && 'error' in response.data)
			return this.onError(response.data.error)
		else return response
	}

	get (path, scheme='CP') {
		return this.ajax.get(path, this.getHeaders(scheme))
	}

	post (path, payload, scheme='CP', config={}) {
		payload['account_id'] = PhoneComUser.getAPIAccountId()
		payload['extension_id'] = PhoneComUser.getExtensionId()
		return this.ajax.post(path, payload, Object.assign(config, this.getHeaders(scheme)))
	}
}

export default new Ajax()